<template>
  <!-- 我关注的空投 -->
  <div class="air-drop">
    <div class="grid-7 air-drop-title" v-if="follows.length > 0">
      <p class="grid1" style="width: 300px; text-indent: 2em">Project</p>
      <p class="grid2 Participated">Participated</p>
      <p class="grid3 Winners" style="width: 60px">Winners</p>
      <p class="grid4 Winners" style="width: 130px">Airdrop Amount</p>
      <div class="grid5 bettwen">
        <p style="width: 120px">Participate in state</p>
        <p class="Winners" style="width: 120px; text-align: center">state</p>
      </div>
    </div>
    <div
      class="grid-7 contarier cur"
      v-for="(item, index) in follows"
      :key="item.id"
      @click="Underway(item)"
    >
      <div class="grid1 head">
        <img
          :src="item.logo ? item.logo : require('../../static/images/18.png')"
          alt=""
          @error="imageLoad(item.logo, index)"
        />
        <p class="BitHotel">{{ item.name }}</p>
      </div>
      <p class="grid2" style="color: #21e2a9; font-size: 14px; width: 30px">
        {{ $tool.formatMonenyNumber(item.participants) }}
      </p>
      <p class="grid3" style="color: #21e2a9; font-size: 14px; width: 60px">
        {{ item.winnerAddressNumber }}
      </p>
      <p class="grid4" style="color: #21e2a9; font-size: 14px; width: 130px">
        {{ $tool.formatMonenyNumber(item.totalAirdropAmount) }} {{ item.token }}
      </p>
      <div class="grid5 Involved">
        <div class="box-si" v-if="item.currentParticipate">Involved in</div>
        <div class="box-si" v-else>Not Involved in</div>
        <!--  -->
        <div class="Ongoing" v-if="item.airdropState == 'Underway'">
          Ongoing
        </div>
        <div class="Ongoing" v-if="item.airdropState == 'Finished'">End</div>
      </div>
      <!-- <img v-if="item.airdropState == 'Underway'" class="enter-into" style="width: 48px;height: 48px;" src="../../static/images/qieh.png" alt="" srcset=""> -->
    </div>

    <el-empty :image-size="200" v-if="nodate" description=" ">
      <template #image>
        <svg-icon iconClass="empty" class="empty-icon"></svg-icon>
      </template>
    </el-empty>
  </div>
</template>

<script>
import { airdropfollows } from "@/api/controller";
export default {
  data() {
    return {
      follows: [],
      nodate: false,
    };
  },
  created() {
    this.airdropfollows();
  },
  methods: {
    Underway(item) {
      if (item.airdropState == "Underway") {
        this.$router.push({ path: "/airdrop-details", query: { id: item.id } });
      }
    },
    imageLoad(src, index) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        // console.log('显示图片未找到')
        this.follows[index].logo = ""; //显示图片未找到 图片
      }
    },
    async airdropfollows() {
      let res = await airdropfollows();
      // console.log(res, '我关注的空投')
      if (res.code === 0) {
        if (res.data.length > 0) {
          this.follows = res.data;
        } else {
          this.nodate = true;
        }
      } else {
        this.nodate = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.air-drop {
  .air-drop-title {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    padding: 0 25px 0 43px;
    display: flex;
    margin-bottom: 15px;
    // align-content: center;

    // justify-content: space-between;
    .Participated {
      width: 30px;
      white-space: nowrap;
      position: relative;
    }
    .Winners {
      position: relative;
    }
    p {
      font-size: 14px;
      color: #909399;
      white-space: nowrap;
    }
  }
  .contarier {
    padding: 0 25px 0 43px;
    margin-bottom: 15px;
    height: 104px;
    background: #22252c;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head {
      min-width: 300px;
      display: flex;
      align-items: center;
      img {
        width: 56px;
        height: 56px;
        background: #30326e;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
    .Involved {
      display: flex;
      align-items: center;
      div {
        width: 118px;
        height: 37px;
        border-radius: 19px;
        font-size: 14px;
        color: #1edfa6;
        text-align: center;
        line-height: 37px;
        margin: 0 8px;
      }
      .box-si {
        background: rgba(#1edfa6, 0.1);
      }
      .Ongoing {
        border: 1px solid #1edfa6;
      }
    }
    .BitHotel {
      font-size: 16px;
      font-weight: bold;
      color: #909399;
    }
  }

  .grid-7 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .grid1 {
    grid-area: ~"1 / 1 / 2 / 3";
  }
  .grid2 {
    grid-area: ~"1 / 3 / 2 / 4";
    text-align: center;
  }
  .grid3 {
    grid-area: ~"1 / 4 / 2 / 5";
    text-align: center;
  }
  .grid4 {
    grid-area: ~"1 / 5 / 2 / 6";
    text-align: center;
  }
  .grid5 {
    grid-area: ~"1 / 6 / 2 / 8";
    justify-content: flex-end;
    text-align: center;
  }
}
</style>
